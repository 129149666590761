import { Component, OnInit } from '@angular/core';
import { NotificationService } from './shared/services/notification.service';
import * as signalR from '@aspnet/signalr';
import { IPayload } from './layout/Admin/model/PayloadModel';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private notificationService: NotificationService) { }

    ngOnInit(): void {
        // const connection = new signalR.HubConnectionBuilder()
        //     .configureLogging(signalR.LogLevel.Information)
        //     .withUrl('http://localhost:60939/joinportal')
        //    // .withUrl('http://portal.knackbe.in/kpm/joinportal')
        //     .build();

        // connection.start().then(function () {
        //     console.log('Connected!');
        // }).catch(function (err) {
        //     return console.error(err.toString());
        // });

        // connection.on('BroadcastMessage', (payload: IPayload) => {

        //     console.log('payload :');
        //     console.log(payload);

        //     this.notificationService.UpdateNotification(payload);

        // });

    }

    // SendMessage

}
