import { Headers } from '@angular/http';

export class AppGlobal {

    // public static copyUrl = 'https://devportal.knackbe.in/kpm';
    // public static copyUrl = 'http://13.235.248.206/apidata';
    public static copyUrl = 'http://portalapi.knackbe.in/kbportalapi';
    // public static copyUrl = 'http://portal.knackbe.in/kpm';
    // public static copyUrl = 'http://192.168.0.171/kbportalapi/apidata';
    // public static ImageUrlForTaskDetails = "http://192.168.1.201/KPM/KnackBe";
    // public static ImageUrlForTaskDetails = 'https://devportal.knackbe.in/kpm';
    // public static ImageUrlForTaskDetails = 'http://13.235.248.206/apidata';
    public static ImageUrlForTaskDetails = 'http://portalapi.knackbe.in/kbportalapi';
    // public static ImageUrlForTaskDetails = 'http://portal.knackbe.in/kpm';
    // public static ImageUrlForTaskDetails = 'http://192.168.0.171/kbportalapi/apidata';


    public static header: Headers;

    public static defaultImagePath() {
        return 'assets\images\employee.png';
    }

    public static getApiUrl(apiurl: string) {
        let domainurl = '';
        // let domainurl = "http://192.168.1.201/KPM/KnackBe";
        // domainurl = 'http://103.204.119.109:81/kpm';  

        //  domainurl = 'https://portal.knackbetechnologies.in/apidata';
        // domainurl = 'http://portalapi.knackbe.in';
        // if (window.location.hostname === 'localhost') {
        //    domainurl = 'http://portalapi.knackbe.in';
        // }


        //  domainurl = 'https://devportalapi.knackbe.in';
        //  if (window.location.hostname === 'localhost') {
        //     domainurl = 'https://devportalapi.knackbe.in';
        //  }

        // domainurl = 'http://localhost:60939';
        //  if (window.location.hostname === 'localhost') {
        //     domainurl = 'http://localhost:60939';
        //  }

        // domainurl = 'https://portalapi.knackbe.in';
        // if (window.location.hostname === 'localhost') {
        //     domainurl = 'https://portalapi.knackbe.in';
        // }

        // Knackbe server
        domainurl = 'http://portalapi.knackbe.in/kbportalapi';
        if (window.location.hostname === 'localhost') {
            domainurl = 'http://portalapi.knackbe.in/kbportalapi';
        }

        // // //ThinkSmart
        // domainurl = 'http://13.235.248.206/apidata';
        // if (window.location.hostname === 'localhost') {
        //    domainurl = 'http://13.235.248.206/apidata';
        // }

        return domainurl + '/api/' + apiurl;
        
    }

    // public static getHeader() {
    //     const header = new Headers({ 'Content-Type': 'application/json' });
    //     header.append('Access-Control-Allow-Origin', '*');
    //     return header;
    // }

    public static IsLive() {
        return (window.location.host === 'localhost:4202') ? false : true;
    }
    public static getLoginUser() {
        if (this.getLoginUserId() === 0) {
            return {
                UserId: 0,
                Fullname: 'FirstName LastName',
                ProfilePhoto: this.defaultImagePath(),
                FirstName: 'FirstName',
                LastName: 'LastName',
                FollowerCount: 0,
                FollowingCount: 0,
                ConnectCount: 0,
                Proffession: ''
            };
        }
        const imgpath = (localStorage.getItem('ProfilePhoto') !== 'null') ? localStorage.getItem('ProfilePhoto') : this.defaultImagePath();
        return {
            UserId: localStorage.getItem('UserId'),
            Fullname: localStorage.getItem('FirstName') + ' ' + localStorage.getItem('LastName'),
            ProfilePhoto: imgpath,
            FirstName: localStorage.getItem('FirstName'),
            LastName: localStorage.getItem('LastName'),
            FollowerCount: localStorage.getItem('FollowerCount'),
            FollowingCount: localStorage.getItem('FollowingCount'),
            ConnectCount: localStorage.getItem('ConnectCount'),
            Proffession: localStorage.getItem('Proffession')
        };
    }
    public static getLoginUserId() {
        return localStorage.getItem('UserId') ? localStorage.getItem('UserId') : 0;
    }

    public static setUser(data: any) {
       console.log('set user to local storage');
        localStorage.setItem('authenticated', data.authenticated);
        localStorage.setItem('token', data.token);
        localStorage.setItem('employeeId', data.employeeId);
        localStorage.setItem('expiration', data.expiration);
        localStorage.setItem('roleId', data.roleId);
        localStorage.setItem('employeeName', data.employeeName);
        localStorage.setItem('employeeImg', data.employeeImg);

    }
    public static getUser() {
        return {
            authenticated: localStorage.getItem('authenticated'),
            token: localStorage.getItem('token'),
            employeeId: localStorage.getItem('employeeId'),
            expiration: localStorage.getItem('expiration'),
            roleId: localStorage.getItem('roleId'),
            employeeName: localStorage.getItem('employeeName'),
            employeeImg: localStorage.getItem('employeeImg')

        };
    }


    public static setprojectinfo(projectinfo: any) {
        localStorage.setItem('estimatedDeadline', projectinfo.estimatedDeadline);
        localStorage.setItem('id', projectinfo.id);
        localStorage.setItem('projectDescription', projectinfo.projectDescription);
        localStorage.setItem('projectTitle', projectinfo.projectTitle);
        localStorage.setItem('status', projectinfo.status);
    }

    public static getprojectinfo() {
        return {
            estimatedDeadline: localStorage.getItem('estimatedDeadline'),
            id: localStorage.getItem('id'),
            projectDescription: localStorage.getItem('projectDescription'),
            projectTitle: localStorage.getItem('projectTitle'),
            status: localStorage.getItem('status'),
        };
    }

    public static logoutUser() {
        localStorage.removeItem('authenticated');
        localStorage.removeItem('token');
        localStorage.removeItem('employeeId');
        localStorage.removeItem('expiration');
        localStorage.removeItem('roleId');
        localStorage.removeItem('employeeName');
        localStorage.removeItem('estimatedDeadline');
        localStorage.removeItem('id');
        localStorage.removeItem('projectDescription');
        localStorage.removeItem('projectTitle');
        localStorage.removeItem('status');
        localStorage.removeItem('isLoggedin');

    }


    public static checkTokenExp() {
        const expTime = new Date(AppGlobal.getUser().expiration);

        const currentTime = new Date(Date.now());
        // console.log(currentTime.getTime());

        if (currentTime.getTime() > expTime.getTime()) {
            return true;
        } else {
            return false;
        }
    }
}
