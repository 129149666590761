import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { IPayload } from 'src/app/layout/Admin/model/PayloadModel';
import { AppGlobal } from '../global';


@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    payload: IPayload;
    workitem: number;

    private notiSource = new BehaviorSubject(this.payload);
    currentNotification = this.notiSource.asObservable();


    private workItemSource = new BehaviorSubject(this.workitem);
    currentWorkItem = this.workItemSource.asObservable();


    constructor() { }

    UpdateNotification(payload: IPayload) {
        if (AppGlobal.getUser().employeeId === payload.UserId) {
            this.notiSource.next(payload);
        }
    }

    UpdateWorkItem(workitem: number) {
        this.workItemSource.next(workitem);
    }

}
